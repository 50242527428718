.time-range-input__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-l);

    & .time-window__button--delete {
        position: absolute;
        left: calc(100% + 0.4rem);
        top: 50%;
        transform: translate(0, -50%);
    }

    .react-datepicker__input-container {
        input.time-range-input__input {
            border: none;
            border-radius: var(--border-radius-l);
        }
    }

    & .react-datepicker {
        display: flex;
        flex-direction: column-reverse;

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__input-time-container {
            display: flex;
            width: 20rem;
            flex-direction: column;
            margin: 0;
            padding: 1rem 1.2rem;

            .react-datepicker-time__caption {
                margin-bottom: 0.5rem;
            }

            .react-datepicker-time__input-container {
                .react-datepicker-time__input {
                    margin: 0;
                    width: 100%;

                    .react-datepicker-time__input {
                        margin: 0;
                        width: 100%;
                        padding: 2rem 1rem;
                    }
                }
            }
        }
    }
}
