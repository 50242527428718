.duplicate-task-modal-actions {
    background-color: var(--color-comet);
    gap: 0;

    &__radio-input-group {
        flex-direction: column;
        row-gap: 1rem;
    }

    &__radio-label {
        width: 100%;
        border-radius: var(--border-radius-l);
        border: 0.1rem solid var(--color-galaxy-90);
        padding: 2rem 2.4rem;
        column-gap: 0.8rem;
        align-items: flex-start;

        &--checked {
            border-color: var(--color-meteor);
        }
    }

    &__radio-input-text {
        row-gap: 0.8rem;
    }

    &__radio-input-label {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
        color: var(--color-galaxy-800-alt);
    }

    &__radio-label-description {
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        color: var(--color-galaxy-30);
    }
}
